th {
    width: 40%;
}

thead th {
    text-align: center;
}

div.statistics a {
    color: darkorange;
}