button.plus {
    margin: 0.5rem;
}

select {
    margin-bottom: 1rem;
}

button {
    float: right;
}

@media screen and (max-width: 500px) {
    button {
        margin-bottom: 0.5rem;
    }
}

@media screen and (min-width: 500px) {
    button {
        margin-bottom: 2rem;
    }
}