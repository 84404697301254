div.whisky-image {
    max-height: 500px;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

div.statistics {
    padding: 0 0.5rem 0.5rem 0.5rem;
}

h2, h4 {
    font-family: 'Nunito', sans-serif;
}

h4 {
    padding-top: 1.5rem;
}

p.rating {
    font-weight: bold;
}

a.btn-edit {
    color: #000;
}