small.additionalDescription {
    display: block;
}

@media screen and (max-width: 500px) {

    small.festival-dram {
        display: none;
    }
    
    small.festival-dram-small {
        display: inline-block;
    }
    
}

@media screen and (min-width: 500px) {

    small.festival-dram {
        display: inline;
    }
    
    small.festival-dram-small {
        display: none;
    }
    
}