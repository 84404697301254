@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Montserrat&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  background-color: floralwhite;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div#root {
  margin: auto;
  max-width: 1200px;
}

table.search-table {
  max-width: 1200px;
}

a {
  color: #FFF;
  text-decoration: none;
}

table a:hover, tr:hover a {
  color: rgb(255, 203, 140);
}
